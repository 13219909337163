.endContactBox{
    background-color: var(--ContactBoxColor);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 2vw;
    padding-bottom: 20px;
    padding-top: 5px;
    margin-top: 20px;
}

.logoMenuBox{
    display: flex;
    flex-direction: column;
}

.endBoxLogo{
    max-width: 15%;
    min-width: 200px
}

.endLogoClass{
    width: 100%;
    height: auto;    
}

.endBoxMenu{
    color: var(--MainTextColor);
    display: flex;
    flex-direction: column;
}

.endBoxMenu h4{
    margin: 0;
    margin-bottom: 5px;
}

.endBoxMenu ul{
    margin: 0;
    padding-left: 10px;
    list-style: none;  
}

.navButtom{     
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--MainTextColor);       
}

.navButtom:hover{     
    font-weight: 500;
    cursor: pointer; 
}

.endBoxAddress h4,
.endBoxBilling h4{
    margin: 0;
    margin-bottom: 2px;
}

.endBoxAddress p,
.endBoxBilling p{
    margin: 1px 0;
    padding-left: 10px;
    font-size: 0.8rem;
}

.endBoxLang{

}

.endBoxLogo,
.endBoxMenu,
.endBoxAddress,
.endBoxBilling,
.endBoxLang {
    margin: 0 auto;
    margin-bottom: 10px;
    min-width: 250px; /* Adjust as necessary */

}