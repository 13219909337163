body,
#root{
  margin: 0;
  padding: 0;
  font-family: Roboto; 
  overflow: hidden;   
}

.App{ 
  height: 100dvh;
  width: 100dvw;
  display: flex;
  background-color: var(--BackgroundColor);
}

.appBody{
  display: flex;
  flex-direction: column;
  flex: 1;
}

.subAppBody{
  overflow-x: hidden;
  overflow-y: auto;
}

@media (min-width: 1300px){
  .appBody{
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (max-width: 1300px) {
  .appBody{
    padding-left: calc(100*calc(1px - calc((1300px - 100vw)/(1300 - 800))));
    padding-right: calc(100*calc(1px - calc((1300px - 100vw)/(1300 - 800))));
  }
}

@media (max-width: 800px){
  .appBody{
    padding-left: 0px;
    padding-right: 0px;
  }
}

:root{
  --BackgroundColor: white;
  --SelectBcgColor: #f5f5f5;
  --ButtonHover: #c2c3c479;
  --ShadowColor: #969696cb;
  --BoxHover: #c2c3c442;
  --MainTextColor: black;
  --ContactBoxColor: #95959679;

}

::-webkit-scrollbar{
  width: 5px;
  position: absolute;
  right: 0;
}

::-webkit-scrollbar-track{
  background: #a0a0a042;
}

::-webkit-scrollbar-thumb{
  background: #88888846;
}

::-webkit-scrollbar-thumb:hover{
  background: #5a5a5a4f;
}
