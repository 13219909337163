.bannerBox{
    display: flex;
}

.picture{
    width: 100%;       
    object-fit: cover;
    object-position: 0px 30%;
}

