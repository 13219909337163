.mainHeader{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 50px;
    position: sticky;
    background-color: var(--BackgroundColor);
}

.logoClass{
    width: 105px;
    height: auto;
}

.mainHeader ul{
    display: flex;
    margin: 0px;
    padding: 0px;
}

.mainHeader ul li {
    list-style: none;    
}

.companyLogo, 
.navButon{
    background-color: transparent;
    display: block;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border: none;
    font-size: 20px;
    text-decoration: none;
    color: var(--MainTextColor);
    text-align: center;
    transition: all 0.2s linear;
}

.navButon:hover,
.companyLogo:hover{
    background-color: var(--ButtonHover);
    font-style: semibold;
    cursor: pointer;
}

.menuHamburger{
    display: none;
    position: absolute;
    right: 1rem;
    flex-direction: column;
    justify-content: space-between;  
    height: 25px;
    width: 30px;
    margin: calc((50px - 25px) / 2) 7px;
}

.hamLine {
    background-color: black;
    width: 100%;
    height: 4px;
    border-radius: 5px;
}

@media (max-width: 800px){
    nav .menuHamburger{
        display: flex;
    }

    .mainHeader {
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
    }

    .mainHeader ul {
        display: none;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
    }

    .mainHeader ul.openMenu {
        display: flex;
        background-color: var(--BackgroundColor);
        padding: 0px;
        margin: 0;        
    }

    .mainHeader ul li {
        width: 100%;
        text-align: center;
    }

    .mainHeader ul li a {
        margin: 0.2rem 0.5rem;
    }

    .companyLogo:hover{
        background-color: transparent;
        font-weight: 400;
    }
}


