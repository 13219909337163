.language-switcher {
    position: relative;
    display: inline-block;
  }

  .dropdown-btn .flag {
    width: 20px;
    height: 15px;
    margin-right: 8px;
  }  
  
  .dropdown-btn{
    background-color: var(--SelectBcgColor);
    color: black;
    padding: 8px 12px;

    border: none;
    cursor: pointer;
    border-radius: 3px;
    font-size: 1rem;
  }
  
  .dropdown-btn:hover {
    background-color: var(--ButtonHover);
  }
  
  .dropdown-menu {
    display: block;
    position: absolute;
    background-color: var(--SelectBcgColor);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: 5px;
    border-radius: 5px;
    width: 100%;
    padding: 5px 5px;
    bottom: 100%; /* Position above the button */
  }
  
  .dropdown-menu li {
    padding: 10px;
    cursor: pointer;
    list-style: none;
    border-radius: 5px;
  }
  
  .dropdown-menu li:hover {
    background-color: var(--ButtonHover);
  }

  .dropdown-menu .flag {
    width: 20px;
    height: 15px;
    margin-right: 8px;
  }