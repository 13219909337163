.infoBoxMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    border: solid 1px black;
    box-shadow: 0px 0px 10px var(--ShadowColor);
    transition: all 0.2s linear;

}

.infoBoxMain:hover{
    background-color: var(--BoxHover);
    cursor: pointer;
    transform: scale(1.02) ;
}

.infoPic {
    max-width: 85%;
    border-radius: 5px;    
}

