.buttonClass{
    padding: 15px;
    margin: 2px;
    background-color: transparent;
    border: none;
    font-size: 20px;
}

.buttonClass:hover{
    background-color: var(--ButtonHover);
    font-weight: 700;
    cursor: pointer;
}