.contactsBox,
.addressBox,
.billingBox{
    display: block;
    flex-direction: column;
    width: 300px;
    margin-bottom: 35px;
    margin-left: 20px;

    & p{
        margin: 5px 0px;
    }

    & a{
        margin: 5px 0px;
        text-decoration: none;
        color: var(--MainTextColor);
    }
}

