.servicesHeader{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    position: sticky;
    background-color: var(--BackgroundColor);
}

.servicesHeader ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
}

.servicesHeader ul li {
    list-style: none;    
}

.servicesBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.servicesBox h2{
    text-align: center;
}

.serviceDIV{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.serviceDIV p{
    text-align: center;
    padding: 0 10px;
}

.propertiesBox{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.propertiesBox ul{
    flex-wrap: wrap;
    padding-right: 10px;    
}

@media (min-width: 800px) {
    .propertiesBox ul{
        max-width: 55%;      
    }
}
  
@media (max-width: 800px){
    .propertiesBox ul{
        max-width: 80%;      
    }
}

.servicePic{
    max-width: 35%;
    height: auto;
    border-radius: 5px;
    margin: auto 0;
}