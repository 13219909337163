.aboutUsText{
    margin: 0px 1rem;
    text-align: justify;
}

.ourPartners{
    display: flex;
    flex-direction: column;
}

.ourPartners h3{
    margin-bottom: 10px;
}


.ourPartnersLinks{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}

.ourPartnersLink{
    width: 30%;
    padding: 0 auto;
}

.partnersLogos{
    width: 80%;
    height: auto;
}