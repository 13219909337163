.midyBody{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.contacts,
.address_billing{
    display: block;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: left;

    & h3{
        font-size: 1.5rem;
        margin-bottom: 15px;
        padding-left: 15px;
        box-shadow: 0 5px 6px -10px rgba(0, 0, 0, 0.5), 0 10px 5px -6px rgba(0, 0, 0, 0.2);
    }
}

