.activityBox{
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    margin: 0px auto;
}

.navInfoBox{
    text-decoration: none;
    color: var(--MainTextColor);
}

.short_description_box{
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}